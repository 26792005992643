import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

export const getPubkey = (params: any): Promise<any> =>
  request.get("/api/auth/pubkey", {
    params,
    loading: true,
  });
