






























import userModule from "@/store/modules/user";
import { Vue, Component, Ref } from "vue-property-decorator";
import { getPubkey } from "@/api/modules/pubkey";

@Component({
  name: "Login",
})
export default class Login extends Vue {
  @Ref("form") formRef: any;
  private form = {
    username: "",
    password: "",
    method: "PASSWORD",
  };

  private rules = {
    username: [{ required: true, message: "请输入账号", trigger: "change" }],
    password: [{ required: true, message: "请输入密码", trigger: "change" }],
  };
  mounted() {
    getPubkey({}).then((res: any) => {
      this.$authModule.setPubkey({ pubkey: res.content });
    });
  }
  onSubmit() {
    this.formRef.validate((valid: boolean) => {
      if (valid) {
        userModule.Login(this.form);
      } else {
        return false;
      }
    });
  }
}
